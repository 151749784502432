
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent,
        IonGrid, IonCol, IonRow, IonButtons, IonButton, IonBackButton,
        IonCard, IonCardTitle, IonCardHeader, IonCardContent, IonNote,
        IonItem, IonLabel, IonInput, IonTextarea, IonDatetime,
        loadingController, alertController, onIonViewDidEnter,
        isPlatform, getPlatforms } from '@ionic/vue';
import LogoImg from '@/components/LogoImg.vue';

import { reactive, ref } from 'vue';
// services
import firebase from 'firebase/app';
import 'firebase/auth';
import { cfaSignInPhone, cfaSignInPhoneOnCodeSent } from 'capacitor-firebase-auth'; // for fixing iOS reCAPTCHA bug
import { useI18n } from 'vue-i18n';
import { utils } from '@/composables/utils';
import { useStore } from 'vuex';
import AuthService from '@/services/AuthService';
import UserService from '@/services/UserService';

export default {
  name: 'RegisterPage',
  components: { IonPage, IonHeader, IonToolbar, IonTitle, IonContent,
                IonGrid, IonCol, IonRow, IonButtons, IonButton, IonBackButton,
                IonCard, IonCardTitle, IonCardHeader, IonCardContent, IonNote,
                IonItem, IonLabel, IonInput, IonTextarea, IonDatetime,
                LogoImg, },
  setup() {
    const store = useStore();
    const newUser = reactive({
      phone: "",
      loginEmail: "",
      password: "",
      confirmPsw: "",
    });
    const registeringAccount = ref(false);

    const emailInput = ref(null);
    const pswInput = ref(null);
    const confirmPswInput = ref(null);

    const window: any = {
      recaptchaVerifier: undefined,
      confirmationResult: undefined,
      verificationId: undefined, // for iOS
    };
    onIonViewDidEnter(() => {
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('grecaptcha-div', {
        'size': 'invisible',
        'callback': (res: any) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
        }
      });
    });

    // methods
    const { t } = useI18n();
    const { presentAlert, reloadApp, presentVerifyCodeInputAlert, focusInputField, } = utils();

    const iPhoneNativeApp = () => (isPlatform('ios') && !isPlatform('mobileweb'));
    const verifyCode = async (verificationCode: any, email: any, loginPsw: any) => {
      const loading = await loadingController.create({ message: t('registeringAccount') });
      await loading.present();
      try {
        let res: any = {};
        if (iPhoneNativeApp()) {
          const phoneCredential = firebase.auth.PhoneAuthProvider.credential(window.verificationId, verificationCode);
          res = await firebase.auth().signInWithCredential(phoneCredential);
        } else {
          res = await window.confirmationResult.confirm(verificationCode);
        }
        const credential = firebase.auth.EmailAuthProvider.credential(email, loginPsw);
        res.user?.linkWithCredential(credential); // link with email for password login
        await UserService.createNewUser({ uid: res.user?.uid, ...newUser, });
        loading.dismiss();
        alertController.dismiss();
        //reloadApp();
      } catch (e) {
        loading.dismiss();
        if (e.code == "auth/invalid-verification-code") { // wrong verification code
          presentAlert(t('RegisterPage.invalidVerificationCode'))
        } else {
          presentAlert(e.message);
        }
      }
    }
    const presentCodeInputAlert = async (phone: any, loginEmail: any, password: any) => {
      await presentVerifyCodeInputAlert(phone, (verificationCode: any) => {
        const email = loginEmail || `${phone}@example.com`;
        verifyCode(verificationCode, email, password);
      });
    }
    const doRegister = async () => {
      const { phone, loginEmail, password, confirmPsw } = newUser;
      if (phone && password && confirmPsw) {
        if (password == confirmPsw) {
          if (/^[456789]\d{7}$/.test(phone) === false) {
            return presentAlert(t('RegisterPage.enterValidHKMobileNumber'));
          }
          registeringAccount.value = true;
          const phoneExists = await AuthService.checkPhoneExists(phone);
          if (phoneExists) {
            registeringAccount.value = false;
            return presentAlert(t('RegisterPage.phoneRegistered'));
          }
          const loading = await loadingController.create({ });
          await loading.present();
          try {
            // verify the phone
            const verifyingPhone = `+852${phone}`;
            if (iPhoneNativeApp()) {
              cfaSignInPhone(verifyingPhone).subscribe((user) => {
                console.log(user);
              })
              cfaSignInPhoneOnCodeSent().subscribe((verificationId) => {
                window.verificationId = verificationId;
                presentCodeInputAlert(phone, loginEmail, password);
              })
            } else {
              const appVerifier = window.recaptchaVerifier;
              const confirmationResult = await firebase.auth().signInWithPhoneNumber(verifyingPhone, appVerifier);
              window.confirmationResult = confirmationResult;
              presentCodeInputAlert(phone, loginEmail, password);
            }
            registeringAccount.value = false;
          } catch (e) {
            registeringAccount.value = false;
            loadingController.dismiss();
            if (e.code == "auth/invalid-phone-number") {
              presentAlert(t('RegisterPage.enterValidHKMobileNumber')); // invalid phone number
            } else {
              presentAlert(e.message);
            }
          }
        } else {
          // password not match
          presentAlert(t('RegisterPage.inconsistentPasswordMsg'));
        }
      } else {
        presentAlert(t('RegisterPage.enterPhoneAndPassword'));
      }
    };
    
    return {
      // variables
      newUser, registeringAccount,
      emailInput, pswInput, confirmPswInput,

      // methods
      t, focusInputField, doRegister,
    }
  },
}
